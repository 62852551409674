/* eslint-disable no-shadow */
import React, { useState, ReactElement } from 'react';
import Button from '../button/Button';
import Icon from '../icon/Icon';
import './Search.scss';

interface OnEventProp {
  value: string;
}

export interface SearchProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onSubmit'>,
    React.ChangeEventHandler {
  omitButton?: boolean;
  returnValOnChange?: boolean;
  placeholderValue?: string;
  buttonText?: string;
  buttonIcon?: string;
  onChange?: (value: OnEventProp) => void;
  onSubmit?: (value: OnEventProp) => void;
}

const defaultProps = {
  omitButton: false,
  placeholderValue: '',
  buttonText: 'Search',
};

const Search = ({
  omitButton,
  placeholderValue,
  buttonText,
  buttonIcon,
  onChange,
  onSubmit,
  ...shared
}: SearchProps): ReactElement => {
  const [value, setValue] = useState('');

  const returnOnChange = (input: string) => {
    if (onChange) onChange({ value: input });
  };

  const returnOnSubmit = (input: string) => {
    if (onSubmit) onSubmit({ value: input });
  };

  const handleButton = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    returnOnSubmit(value);
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') returnOnSubmit(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    const eventValue = event.target.value;
    setValue(eventValue);
    if (onChange) returnOnChange(eventValue);
  };

  const clearText = (): void => {
    setValue('');
    if (onChange) returnOnChange('');
    returnOnSubmit('');
  };

  return (
    <section className={`uikit_search_container + ${shared.disabled ? ' disabled' : ''}`}>
      <div className="uikit_search_container__input-wrapper">
        <span className="uikit_search_container__input-wrapper__icon">
          <i className="fal fa-search" />
        </span>

        <input
          type="text"
          placeholder={placeholderValue}
          name="search"
          value={value}
          onChange={handleChange}
          onKeyPress={handleEnter}
          {...shared}
        />
        {value && value.length > 0 && (
          <div className="uikit_search_container__input-wrapper__clear-icon">
            <Icon styled="primary" icon="fal fa-times-circle" onClick={clearText} />
          </div>
        )}
      </div>

      {!omitButton && (
        <div className="uikit_search_container__button">
          {buttonIcon ? (
            <Icon styled="primary" icon={buttonIcon} disabled={shared.disabled} onClick={handleButton} />
          ) : (
            <Button type="button" disabled={shared.disabled} onClick={handleButton}>
              {buttonText}
            </Button>
          )}
        </div>
      )}
    </section>
  );
};

Search.defaultProps = defaultProps;

export default Search;
