/* eslint-disable react/no-danger */
import React, { ReactElement, SyntheticEvent, useState, useRef, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import ErrorWrapper, { ErrorProps } from '../ErrorWrapper';
import './AutoComplete.scss';

export interface LabelPairValue {
  label: string;
  value: string | null;
  highlightedLabel?: string;
  image?: string;
  metadata?: any;
}

interface Dictionary<T> {
  [details: string]: T;
}

function regExpEscape(text: string): string {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const highlight = (result: string, searchTextValue: string): Array<string> => {
  const terms: Array<string> = Array.isArray(searchTextValue) ? searchTextValue : [searchTextValue];
  const escapedTerms = terms.map(term => regExpEscape(term)).filter(term => term);
  return escapedTerms.length ? result.split(new RegExp(`(${escapedTerms.join('|')})`, 'gmi')) : [result];
};

export const highlightItem = (searchTextValue: string, option: LabelPairValue): LabelPairValue => {
  if (
    searchTextValue &&
    searchTextValue.length > 0 &&
    option &&
    option.label.toLowerCase().includes(searchTextValue.toLowerCase())
  ) {
    const updatedOption = { ...option };
    updatedOption.highlightedLabel = '';
    const result = highlight(option.label, searchTextValue);
    let newLabel = '';
    result.forEach((item, index) => {
      if (index % 2 === 0) {
        newLabel += item;
      } else {
        newLabel += `<b>${item}</b>`;
      }
    });
    updatedOption.highlightedLabel = newLabel;
    return updatedOption;
  }
  return option;
};

interface AutoCompleteProps
  extends Omit<ErrorProps, 'children'>,
    Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className' | 'ref' | 'children'> {
  name: string;
  label: string;
  handler: (theValue: LabelPairValue) => LabelPairValue;
  autoCompleteItems: Array<LabelPairValue>;
  totalCount?: number;
  // query?: (options?: QueryLazyOptions<any> | undefined) => void;
  onLoadMore?: any;
  renderDisplay?: (item: LabelPairValue, inputValue: string) => JSX.Element;
  fixedQueryVariables?: Dictionary<any>;
  openOnFocus?: boolean;
  inputTestId?: string;
  testId?: string;
  selectedItem?: LabelPairValue;
}

const useOutsideAlerter = (ref: any, setOpen: React.Dispatch<React.SetStateAction<boolean>>): void => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: any): void => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return (): any => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setOpen]);
};

export const AutoComplete = React.forwardRef(
  (
    {
      name,
      label,
      handler,
      autoCompleteItems,
      onLoadMore,
      totalCount,
      openOnFocus,
      errors,
      renderDisplay,
      inputTestId,
      testId,
      ...props
    }: AutoCompleteProps,
    ref: React.Ref<HTMLInputElement>
  ): ReactElement => {
    const { defaultValue, value, disabled } = props;
    const activeOptionRef = useRef<HTMLDivElement>(null);
    const [inputValue, setInputValue] = useState(defaultValue || '');
    const [inputLabel, setInputLabel] = useState(value || '');
    const [items, setItems] = useState<LabelPairValue[]>([]);
    const [isOpen, setOpen] = useState(false);
    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const wrapperRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      const item = autoCompleteItems.find(c => c.value === defaultValue);
      if (item && item.value) {
        setInputValue(item.value);
        setInputLabel(item.label);
      } else {
        setInputValue('');
        setInputLabel('');
      }
    }, [defaultValue]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
      setItems(autoCompleteItems);
    }, [autoCompleteItems]);

    useEffect(() => {
      if (activeOptionRef && activeOptionRef.current && activeOptionRef.current.scrollIntoView) {
        activeOptionRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }, [activeSuggestion]);

    const executeMemoryQuery = (searchTerm: string): void => {
      if (searchTerm === '') {
        setItems(autoCompleteItems);
      } else {
        const filtered = autoCompleteItems.filter(a => a.label.toLowerCase().includes(searchTerm.toLowerCase()));
        setItems(filtered);
      }
    };

    const executeQuery = (searchTerm: string): void => {
      // if (query) {
      //   const variables = { searchTerm, ...fixedQueryVariables };
      //   query({ variables });
      // } else {
      executeMemoryQuery(searchTerm);
      // }
    };

    const debouncedCallback = useDebouncedCallback((searchTerm: string) => {
      executeQuery(searchTerm);
    }, 0);

    const handleInputFocus = (): void => {
      if (openOnFocus) {
        executeQuery('');
        setOpen(true);
      }
    };

    const dropdownInputFocus = (): void => {
      // event.preventDefault();
      setActiveSuggestion(0);
      const toggledValue = !isOpen;
      if (toggledValue) {
        executeQuery('');
      }

      setOpen(toggledValue);
    };

    const keyUpHandler = (event: SyntheticEvent): void => {
      const target = event.target as HTMLInputElement;
      setInputValue(target.value);
      debouncedCallback.callback(target.value);
    };

    const handleSelectedItem = (item: LabelPairValue): void => {
      // if (item) {
      //   setInputLabel(item.label);
      //   if (item.value) setInputValue(item.value);
      //   handler(item);
      //   setOpen(false);
      // }
      if (item && item.value) {
        setInputLabel(item.label);
        setInputValue(item.value);
        handler(item);
        setOpen(false);
      } else {
        setInputLabel('');
        setInputValue('');
        handler({ label: '', value: '' });
      }
    };

    const keyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>): void => {
      if (event.keyCode === 13 || event.key === 'Enter') {
        const activeSuggestedItem = items[activeSuggestion];
        setActiveSuggestion(activeSuggestion);
        handleSelectedItem(activeSuggestedItem);
        setOpen(!isOpen);
      } else if (event.keyCode === 38 || event.key === 'ArrowUp') {
        if (activeSuggestion === 0) {
          setActiveSuggestion(activeSuggestion);
          return;
        }
        setActiveSuggestion(activeSuggestion - 1);
        setOpen(true);

        // User pressed the up arrow, decrement the index
      } else if (event.keyCode === 40 || event.key === 'ArrowDown') {
        if (activeSuggestion === items.length - 1) {
          setActiveSuggestion(activeSuggestion);
          return;
        }

        setActiveSuggestion(activeSuggestion + 1);
        setOpen(true);
      } else {
        setOpen(true);
      }
    };

    const selectItem = (item: LabelPairValue, index: number) => (event: SyntheticEvent): any => {
      event.preventDefault();
      event.stopPropagation();
      setActiveSuggestion(index);
      handleSelectedItem(item);
    };

    const changedHandler = (event: React.ChangeEvent<HTMLInputElement>): any => {
      setInputLabel(event.target.value);
      const matchingItems = autoCompleteItems.filter(a => a.label.toLowerCase() === event.target.value.toLowerCase());
      const filtered = autoCompleteItems.filter(a => a.label.toLowerCase().includes(event.target.value.toLowerCase()));
      setItems(filtered);
      if (matchingItems.length === 0) {
        setActiveSuggestion(0);
      }

      handler({ label: event.target.value, value: matchingItems.length > 0 ? matchingItems[0].value : null });
    };

    const rawMarkup = (html: string): any => {
      return { __html: html };
    };

    function handleScroll(event: SyntheticEvent<HTMLDivElement>): void {
      if (event.currentTarget.scrollTop + event.currentTarget.clientHeight >= event.currentTarget.scrollHeight) {
        if (onLoadMore && totalCount && items && items.length < totalCount) {
          onLoadMore();
        }
      }
    }

    useOutsideAlerter(wrapperRef, setOpen);

    const classNameConcat = `uikit_autocomplete_container`;

    return (
      <div ref={wrapperRef} className="autocomplete">
        {/* <ValidationControl controlName={name || ''} errors={errors}> */}
        <ErrorWrapper name={name} label={label} className={classNameConcat} {...props} errors={errors}>
          <div className="input-container">
            <input
              name={name}
              type="text"
              data-testid={inputTestId}
              className="TextInput"
              value={inputLabel}
              onFocus={handleInputFocus}
              onKeyUp={keyUpHandler}
              onChange={changedHandler}
              onKeyDown={keyDownHandler}
              autoComplete="off"
              ref={ref}
            />
            {!disabled && (
              <div
                className="dropdown-indicator"
                role="button"
                tabIndex={0}
                onClick={dropdownInputFocus}
                onKeyPress={dropdownInputFocus}
              >
                <i className="fal fa-chevron-down" />
              </div>
            )}
            <input data-testid={testId} type="hidden" value={inputValue} />
          </div>
        </ErrorWrapper>
        {/* </ValidationControl> */}
        <div className="autocomplete-items" onScroll={handleScroll}>
          {!disabled &&
            isOpen &&
            items &&
            items.map((item: LabelPairValue, index: number) => {
              let className = 'autocomplete-item';
              if (index === activeSuggestion) {
                className = 'autocomplete-item suggestion-active';
              }
              return (
                <div
                  key={index}
                  ref={index === activeSuggestion ? activeOptionRef : null}
                  role="button"
                  className={className}
                  tabIndex={0}
                  onClick={selectItem(item, index)}
                  onKeyPress={selectItem(item, index)}
                  onMouseEnter={(): void => setActiveSuggestion(index)}
                >
                  {renderDisplay && renderDisplay(item, inputValue?.toString())}
                  {!renderDisplay && (
                    <div
                      dangerouslySetInnerHTML={rawMarkup(
                        highlightItem(inputValue?.toString(), item).highlightedLabel || ''
                      )}
                    />
                  )}
                </div>
              );
            })}
        </div>
      </div>
    );
  }
);

AutoComplete.defaultProps = {
  openOnFocus: true,
  onLoadMore: null,
  totalCount: undefined,
  renderDisplay: undefined,
  fixedQueryVariables: undefined,
  inputTestId: undefined,
  testId: undefined,
  selectedItem: undefined,
};

export default AutoComplete;
