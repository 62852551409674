import React, { useState, useCallback, useEffect } from 'react';
import './Radio.scss';
import { RadioGroupProps } from './interface';
import ErrorWrapper, { ErrorProps } from '../ErrorWrapper';

export interface CombinedProps extends RadioGroupProps, Omit<ErrorProps, 'children'> {}

export const RadioGroup = React.forwardRef(
  (
    { value, children, onChange, id, className, name, ...shared }: CombinedProps,
    ref: React.Ref<HTMLInputElement>
  ): React.ReactElement => {
    const [groupSelected, setGroupSelected] = useState(value);
    const classNameConcat = `uikit_radiobutton-container ${className}`;

    useEffect(() => {
      setGroupSelected(value);
    }, [value]);

    const selectRadio = useCallback(
      (selectedValue: string | number): void => {
        setGroupSelected(selectedValue);
        if (onChange) onChange({ value: selectedValue, id });
      },
      [id, onChange]
    );

    return (
      <div ref={ref}>
        <ErrorWrapper name={name} className={classNameConcat} {...shared}>
          <div className="uikit_radiobuttons">
            {children.map((radio, key: number) => React.cloneElement(radio, { name, key, groupSelected, selectRadio }))}
          </div>
        </ErrorWrapper>
      </div>
    );
  }
);

RadioGroup.defaultProps = {
  id: 'radio',
  className: '',
  errors: {},
  errorMessage: null,
  name: '',
  label: '',
};

export default RadioGroup;
