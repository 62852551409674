import React, { useState, useCallback, useEffect } from 'react';
import './Checkbox.scss';
import CheckboxGroup from './CheckboxGroup';
import { CheckboxProps } from './interface';

interface CompoundedComponent
  extends React.ForwardRefExoticComponent<CheckboxProps & React.RefAttributes<HTMLInputElement>> {
  Group: any;
  defaultProps: any;
}

const Checkbox = React.forwardRef(
  (
    { name, value, children, disabled, checked, selectCheckbox, groupSelected, className, boxName }: CheckboxProps,
    ref: React.Ref<HTMLInputElement>
  ): React.ReactElement => {
    const [stateChecked, setStateChecked] = useState(groupSelected ? groupSelected.indexOf(value) > -1 : checked);

    useEffect(() => {
      setStateChecked(groupSelected ? groupSelected.indexOf(value) > -1 : checked);
    }, [groupSelected]);

    const selectMyself = useCallback(() => {
      if (!disabled) {
        if (selectCheckbox) {
          selectCheckbox({ value, stateChecked: !stateChecked || false });
        }
        setStateChecked(!stateChecked);
      }
    }, [stateChecked, selectCheckbox, value, disabled]);

    return (
      <button
        className={`uikit_checkbox ${disabled ? 'disabled' : ''} ${className} ${stateChecked ? 'checked' : ''}`}
        onClick={selectMyself}
        type="button"
      >
        <input id={boxName} name={name} value={value} ref={ref} type="checkbox" disabled checked={stateChecked} />
        <span className="innerbox" />
        <label htmlFor={name}>{children}</label>
      </button>
    );
  }
) as CompoundedComponent;

Checkbox.defaultProps = {
  disabled: false,
  checked: false,
  selectCheckbox: (val: object): object => {
    return val;
  },
  groupSelected: null,
  className: '',
};

Checkbox.Group = CheckboxGroup;

export default Checkbox;
