import React, { useCallback } from 'react';
import './Select.scss';
import { OptionProps } from './interface';

const Option = ({
  value,
  children,
  disabled,
  className,
  selectValue,
  selectedValue,
  ...shared
}: OptionProps): React.ReactElement => {
  const selectMyself = useCallback(() => {
    if (!disabled) {
      // TODO: fix this...
      // @ts-ignore
      selectValue(value);
    }
  }, [disabled, selectValue, value]);

  let classNameConcat = 'uikit_select_option';
  if (className) {
    classNameConcat = `${classNameConcat} ${className}`;
  }
  if (disabled) {
    classNameConcat = `${classNameConcat} disabled`;
  }

  if (selectedValue === value) {
    classNameConcat = `${classNameConcat} selected`;
  }

  return (
    <li>
      <div
        className={classNameConcat}
        onClick={selectMyself}
        tabIndex={0}
        role="link"
        onKeyDown={selectMyself}
        {...shared}
      >
        {children}
      </div>
    </li>
  );
};

Option.defaultProps = {
  disabled: false,
  className: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  selectValue: (): void => {},
  selectedValue: '',
};

export default Option;
