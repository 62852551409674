/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement } from 'react';
import Nouislider, { Callback } from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';
import './Slider.scss';

export interface SliderProps {
  sliderRange: number[];
  value: any[];
  label: string;
  steps?: number;
  pips?: any;
  connectProps?: boolean[];
  disabled?: boolean;
  className?: string;
  updateHandler: (unencodedValues: number[]) => void;
}

export const Slider = ({
  sliderRange,
  className,
  value,
  label,
  connectProps = [false],
  steps = 1,
  updateHandler,
  pips = {
    mode: 'count',
    values: 11,
    density: 10,
    stepped: true,
  },
  disabled = false,
}: SliderProps): ReactElement => {
  const updated: Callback = (unencodedValues: string[]) => {
    const values = unencodedValues.map(v => parseInt(v, 0));
    updateHandler(values);
  };
  const classNameConcat = `uikit_slider_container ${className}`;

  return (
    <div className="ui-slider">
      <div className={classNameConcat}>
        {label && <label>{label}</label>}
        <Nouislider
          range={{ min: sliderRange[0], max: sliderRange[1] }}
          step={steps}
          start={value}
          pips={pips}
          connect={connectProps}
          onUpdate={updated}
          disabled={disabled}
          className={classNameConcat}
        />
      </div>
    </div>
  );
};

export default Slider;
