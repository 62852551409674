import React, { ReactElement } from 'react';
import InputMask from 'react-input-mask';
import ErrorWrapper, { ErrorProps } from '../ErrorWrapper';
import '../input/Input.scss';

interface InputProps extends Omit<ErrorProps, 'children'> {
  mask: string | Array<string | RegExp>;
  maskChar?: string;
  alwaysShowMask?: boolean;
}

const defaultProps = {
  maskChar: '_',
  alwaysShowMask: true,
};

export const MaskedInput = ({
  label,
  mask,
  name,
  className,
  errorMessage,
  errors,
  maskChar,
  ...shared
}: InputProps): ReactElement => {
  const classNameConcat = `uikit_input_container ${className}`;
  return (
    <ErrorWrapper
      label={label}
      name={name}
      className={classNameConcat}
      errorMessage={errorMessage}
      errors={errors}
      {...shared}
    >
      <InputMask mask={mask} maskChar={maskChar} name={name} {...shared} type="text" />
    </ErrorWrapper>
  );
};

MaskedInput.defaultProps = defaultProps;

export default MaskedInput;
