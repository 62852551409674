import React, { useState, useCallback, useEffect } from 'react';
import './Checkbox.scss';
import { CheckboxGroupProps } from './interface';
import ErrorWrapper, { ErrorProps } from '../ErrorWrapper';

export interface CombinedProps extends CheckboxGroupProps, Omit<ErrorProps, 'children'> {}

const CheckboxGroup = React.forwardRef(
  (
    { value, children, onChange, id, className, name, ...shared }: CombinedProps,
    ref: React.Ref<HTMLInputElement>
  ): React.ReactElement => {
    const [groupSelected, setGroupSelected] = useState(value);

    useEffect(() => {
      setGroupSelected(value);
    }, [value]);

    const selectCheckbox = useCallback(
      (val: { value: string; stateChecked: boolean }): void => {
        const newArray = [...groupSelected];
        if (val.stateChecked) {
          newArray.push(val.value);
        } else {
          const index = newArray.indexOf(val.value);
          newArray.splice(index, 1);
        }
        setGroupSelected(newArray);
        if (onChange) onChange({ value: newArray, id });
      },
      [id, onChange, groupSelected]
    );

    const classNameConcat = `uikit_checkbox-container ${className}`;

    return (
      <ErrorWrapper name={name} className={classNameConcat} {...shared}>
        <div ref={ref}>
          <div className="uikit_checkboxes">
            {children.map((Checkbox, key: number) =>
              React.cloneElement(Checkbox, { name, key, groupSelected, selectCheckbox })
            )}
          </div>
        </div>
      </ErrorWrapper>
    );
  }
);

CheckboxGroup.defaultProps = {
  id: 'radio',
  className: '',
  name: 'notset',
};

export default CheckboxGroup;
