import React, { ReactElement, ReactNode } from 'react';
import Icon from '../icon/Icon';
import ErrorWrapper, { ErrorProps } from '../ErrorWrapper';
import './Input.scss';

export interface InputProps
  extends Omit<ErrorProps, 'children'>,
    Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className' | 'ref' | 'name' | 'children'> {
  ref?: React.Ref<HTMLInputElement>;
  icon?: string;
  setValue?: (name: string, value: string) => void;
  watch?: (name: string) => string | string[] | { [key: string]: any } | undefined;
  resetButton?: boolean;
  description?: ReactNode;
}

export const Input = React.forwardRef(
  (
    { name, errorMessage, className, description, icon = '', setValue, watch, ...shared }: InputProps,
    ref: React.Ref<HTMLInputElement>
  ): ReactElement => {
    let innerValue: string | string[] | { [key: string]: any } | undefined = '';
    if (watch) {
      innerValue = watch(`${name}`);
    }

    const classNameConcat = `uikit_input_container ${className}`;
    return (
      <ErrorWrapper name={name} errorMessage={errorMessage} className={classNameConcat} {...shared}>
        <div className={`uikit_input_container ${icon ? 'withIcon' : ''} ${innerValue ? 'withClear' : ''}`}>
          {icon && <Icon styled="none" className="uikit_input_icon" size="normal" icon={icon} />}
          <span className="uikit_input_description">{description}</span>
          <input name={name} {...shared} ref={ref} />
          {innerValue?.length > 0 && setValue && (
            <Icon
              styled="none"
              className="uikit_input_clear"
              size="normal"
              onClick={(): void => {
                setValue(`${name}`, '');
              }}
              icon="fal fa-times-circle"
            />
          )}
        </div>
      </ErrorWrapper>
    );
  }
);

export default Input;
