import React, { ReactElement } from 'react';
import './Badge.scss';

interface Badge extends Omit<React.HTMLAttributes<HTMLElement>, 'className' | 'position'> {
  children: any;
  /** Sets the position of the content on the icon */
  position: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
  /** Allows for custom styling */
  className?: string;
  /** The content listed on the icon */
  value: number;
}
const defaultProps = {
  className: '',
  position: 'top-right',
};

const Badge = ({ value, position, className, children, ...shared }: Badge): ReactElement => {
  let displayValue: string | number = value;
  if (value > 99) {
    displayValue = '99+';
  }
  return (
    <div className={`uikit_badge ${className} ${position}`} {...shared}>
      <span className="uikit_badge_value" title={value.toString()}>
        {displayValue}
      </span>
      {children}
    </div>
  );
};

Badge.defaultProps = defaultProps;

export default Badge;
