export const ErrorMessages = {
  required: '%NAME% is required.',
  minLength: '%NAME% has not reached its minimum length.',
  maxLength: '%NAME% has exceeded its maximum length.',
  min: '%NAME% has not reached its minimum.',
  max: '%NAME% has exceeded its maximum.',
  pattern: '%NAME% violates the needed pattern.',
  manual: '%NAME% does not meet our entry requirements.',
};

export default ErrorMessages;
