import React, { ReactElement, useState } from 'react';
import './Toggle.scss';

interface ToggleProps {
  checked?: boolean;
  onToggle: (value: boolean) => void;
}

export const Toggle = ({ checked, onToggle }: ToggleProps): ReactElement => {
  const [isChecked, toggleChecked] = useState(checked);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    toggleChecked(event.target.checked);
    onToggle(event.target.checked);
  };

  return (
    <label className="toggle-switch">
      <input className="toggle-switch_checkbox" type="checkbox" checked={isChecked} onChange={handleOnChange} />
      <span className="toggle-switch_slider round" />
    </label>
  );
};

Toggle.defaultProps = {
  checked: false,
};

export default Toggle;
