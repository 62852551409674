import React, { ReactElement, useState, useEffect } from 'react';
import './Icon.scss';

type ButtonStyle = 'primary' | 'secondary' | 'none';
type ButtonType = 'button' | 'submit';
type ButtonSize = 'normal' | 'large' | 'xlarge' | 'xxlarge';
type ButtonTextSize = 'normal' | 'large' | 'xlarge' | 'xxlarge';
type ButtonPosition = 'bottom' | 'right';

interface IconProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className' | 'size'> {
  /** Sets the styling for the icon. 'primary' | 'secondary' | 'none' */
  styled?: ButtonStyle;
  /** Sets text to accompany the icon. */
  text?: string;
  textSize?: ButtonTextSize;
  /** Sets the position of the text prop.  */
  position?: ButtonPosition;
  /** Sets the type on the button wrapper for functionality. 'button' | 'submit' */
  type?: ButtonType;
  /** Sets the size of the icon. 'normal' | 'large' | 'xlarge' | 'xxlarge' */
  size?: ButtonSize;
  /** The fontawesome string classname for the icon to be displayed */
  icon: string;
  /** Sets an icon to be displayed when hovered.  */
  iconHover?: string;
  /** Sets an icon to be displayed when focused. */
  iconFocus?: string;
  /** Sets whether the icon should display its focused icon. */
  focused?: boolean;
  className?: string;
}

const defaultProps = {
  text: '',
  position: 'bottom',
  type: 'button',
  styled: 'primary',
  size: 'large',
  textSize: 'normal',
  iconHover: '',
  iconFocus: '',
  focused: false,
  className: '',
};

export const Icon = ({
  styled,
  size,
  type,
  position,
  text,
  textSize,
  icon,
  focused,
  iconHover,
  iconFocus,
  className,
  ...shared
}: IconProps): ReactElement => {
  const [selectedIcon, setSelectedIcon] = useState(focused && iconFocus ? iconFocus : icon);
  const [isFocused, setIsFocused] = useState(focused);

  useEffect(() => {
    if (focused) {
      setIsFocused(true);
    } else {
      setIsFocused(false);
    }
    setSelectedIcon(focused && iconFocus ? iconFocus : icon);
  }, [focused]);

  const onMouseOver = (): void => {
    if (iconHover && !shared.disabled) setSelectedIcon(iconHover);
  };

  const onMouseLeave = (): void => {
    if (iconHover && !isFocused) setSelectedIcon(icon);
    if (iconHover && isFocused && iconFocus) setSelectedIcon(iconFocus);
    if (iconHover && isFocused && !iconFocus) setSelectedIcon(icon);
  };

  return (
    <>
      <button
        role={type}
        type={type}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onFocus={onMouseOver}
        onClick={(): void => {
          setIsFocused(!isFocused);
        }}
        className={`uikit_Icon ${styled} ${size} ${className} ${shared.disabled && 'disabled'}`}
        {...shared}
      >
        <i key={selectedIcon}>
          <span className={selectedIcon} />
        </i>
        {text && <div className={`uikit_Icon_Text ${position} ${textSize}`}>{text}</div>}
      </button>
    </>
  );
};

Icon.defaultProps = defaultProps;

export default Icon;
