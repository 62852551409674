import React, { ReactElement, ReactNode } from 'react';
import Icon from './icon/Icon';
import ErrorMessages from './ErrorMessages';

export interface ErrorProps {
  label?: ReactNode;
  className?: string;
  errors?: {
    [prop: string]: { type: keyof typeof ErrorMessages; message: string; ref: any };
  };
  disabled?: boolean;
  required?: boolean;
  name: string;
  errorMessage?: string | null;
  raw?: boolean;
  children: any;
}

const ErrorWrapper = ({
  label = '',
  className = '',
  errors,
  name,
  errorMessage = null,
  disabled = false,
  required = false,
  raw = false,
  ...shared
}: ErrorProps): ReactElement => {
  let errorMessageDisplay = '';
  let classNameConcat = '';

  if (className) classNameConcat = `${className}`;
  if (disabled) classNameConcat = `${classNameConcat} disabled`;
  if (required) classNameConcat = `${classNameConcat} required`;

  if (errors && errors[name]) {
    if (errors[name].type in ErrorMessages) {
      errorMessageDisplay = errors[name].message === '' ? `${name} is ${errors[name].type}` : errors[name].message;
    } else {
      errorMessageDisplay = `${name} has an error.`;
    }

    // this always overrides above when used...
    if (errorMessage) {
      errorMessageDisplay = errorMessage;
    }
    classNameConcat = `${classNameConcat} error`;
  }

  if (!raw) {
    return (
      <div className={classNameConcat}>
        {label && (
          <label htmlFor={`${name}`}>
            {required && <sup>*</sup>}
            {label} {errorMessageDisplay && <Icon styled="none" size="normal" icon="fal fa-exclamation-circle" />}
          </label>
        )}
        {shared.children}
        {errorMessageDisplay && (
          <span className="errorMessage">
            <sup>*</sup>
            {errorMessageDisplay}
          </span>
        )}
      </div>
    );
  }
  return <div className={classNameConcat}>{shared.children}</div>;
};

export default ErrorWrapper;
