/* eslint-disable react/static-property-placement */
import React, { ReactElement } from 'react';
import { omit } from 'lodash-es';
import AlertContainer from './AlertContainer';
import './Alert.scss';

interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  type: 'alert' | 'success';
  tagName: 'span' | 'container';
  displayName: string;
  className: string;
  closeIcon?: string;
  actionIcon?: string;
}
const defaultProps = {
  type: 'alert',
};

function AlertMaker({ tagName, displayName, type, className }: AlertProps) {
  return (BasicComponent: any): any => {
    return class Adapter extends React.Component<AlertProps> {
      static displayName: string = displayName;

      static Message: any;

      static Details: any;

      render(): ReactElement {
        return (
          <BasicComponent
            className={`${className} ${type}`}
            tagName={tagName}
            {...omit(this.props, ['className', 'tagName'])}
          />
        );
      }
    };
  };
}

const Basic = (props: AlertProps): ReactElement => {
  const { children, tagName, type, ...others } = props;
  if (tagName === 'container') {
    return (
      <AlertContainer type={type} {...others}>
        {children}
      </AlertContainer>
    );
  }
  return React.createElement(tagName, { ...others }, children);
};

const Alert: any & {
  Message: React.ComponentClass<any>;
  Details: React.ComponentClass<any>;
} = AlertMaker({
  tagName: 'container',
  displayName: 'Alert',
  type: 'alert',
  className: 'uikit_alert',
})(Basic);

const Message = AlertMaker({
  tagName: 'span',
  displayName: 'Message',
  className: 'message',
  type: 'alert',
})(Basic);

const Details = AlertMaker({
  tagName: 'span',
  displayName: 'Details',
  className: 'details',
  type: 'alert',
})(Basic);

Alert.Message = Message;
Alert.Details = Details;

Alert.defaultProps = defaultProps;

export default Alert;
