import React, { ReactElement, useRef, useState, useEffect } from 'react';
import Icon from '../icon/Icon';
import { MultiSelectProps, OnChangeTypes, LabelProps } from './interface';
import Checkbox from '../checkbox/index';
import MultiselectSideInput from './MultiselectSideInput';
import ErrorWrapper, { ErrorProps } from '../ErrorWrapper';
import './Multiselect.scss';

interface CombinedProps extends MultiSelectProps, Omit<ErrorProps, 'children'> {}

export const Multiselect = React.forwardRef(
  (
    { name, className, children, tabIndex, ...shared }: CombinedProps,
    nref: React.Ref<HTMLInputElement>
  ): ReactElement => {
    const [selectedValue, setSelectedValue] = useState<string[] | undefined>([]);
    const [allLables, setAllLabels] = useState<LabelProps[] | []>([]);
    const [isOpen, setIsOpen] = useState(false);
    const wrapRef = useRef<HTMLDivElement>(null);

    const toggleOpen = (): void => {
      setIsOpen(!isOpen);
    };

    useEffect(() => {
      const allLablesCopy = React.Children.map(children, child => {
        return { value: child.props.value, label: child.props.children };
      });

      if (allLablesCopy.length > 0) setAllLabels(allLablesCopy);
    }, [children]);

    useEffect(() => {
      function handleClickOutside(event: Event): void {
        if (wrapRef.current && !wrapRef.current.contains(event.target as Node)) {
          setIsOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return (): void => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [wrapRef]);

    const manageMultipleSelection = (selection: OnChangeTypes): void => setSelectedValue(selection.value);

    const removeSlection = (event: React.MouseEvent<HTMLElement>, found: LabelProps): void => {
      event.preventDefault();
      const valueIndex = selectedValue?.findIndex(val => val === found.value);
      if ((valueIndex || valueIndex === 0) && selectedValue) {
        setSelectedValue([...selectedValue.slice(0, valueIndex), ...selectedValue.slice(valueIndex + 1)]);
      }
    };

    let classNameConcat = `uikit_multiselect_container ${className}`;
    if (isOpen) classNameConcat = `${classNameConcat} open`;

    let refValueReturn = null;
    if (selectedValue?.length === 0) {
      refValueReturn = <input name={name} ref={nref} readOnly value="" className="uikit_multiselect_hidden_input" />;
    } else {
      refValueReturn = selectedValue?.map((value, index) => {
        const fieldName = `multi[${index}]`;
        return (
          <input
            key={index}
            name={`${fieldName}.${name}`}
            ref={nref}
            readOnly
            value={value}
            className="uikit_multiselect_hidden_input"
          />
        );
      });
    }

    return (
      <ErrorWrapper name={name} className={classNameConcat} {...shared}>
        <div className="uikit_multiselect_columns" ref={wrapRef}>
          <div className="uikit_multiselect_wrapper">
            <div className="uikit_multiselect_dropdown">
              <div
                className="uikit_multiselect_selected"
                role="button"
                tabIndex={tabIndex}
                onKeyDown={(): void => toggleOpen()}
                onClick={(): void => toggleOpen()}
              >
                <span>Select item...</span>
                <span className="uikit_arrow_icon">
                  <Icon
                    styled="none"
                    icon={`${isOpen ? 'fal fa-angle-up' : 'fal fa-angle-down'}`}
                    onClick={toggleOpen}
                  />
                </span>
              </div>
              {isOpen && (
                <div className="uikit_multiselect_list">
                  <Checkbox.Group value={selectedValue || []} id="myCheckbox" onChange={manageMultipleSelection}>
                    {React.Children.map(children, child => {
                      return <Checkbox {...child.props} />;
                    })}
                  </Checkbox.Group>
                </div>
              )}
              {refValueReturn}
            </div>
          </div>
          <MultiselectSideInput selectedValue={selectedValue} removeSlection={removeSlection} allLables={allLables} />
        </div>
      </ErrorWrapper>
    );
  }
);

export default Multiselect;
