import React, { ReactElement } from 'react';
import Icon from '../icon/Icon';

type AlertType = 'alert' | 'success';

export interface AlertContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  type: AlertType;
  onClose: () => void;
  closeIcon?: string;
  actionIcon?: string;
}
const defaultProps = {
  type: 'alert',
  onClose: (): void => {},
};

export const AlertContainer = ({
  type,
  children,
  onClose,
  closeIcon = 'fal fa-times-circle',
  actionIcon = type === 'alert' ? 'fal fa-exclamation-circle' : 'fal fa-check-circle',
  ...shared
}: AlertContainerProps): ReactElement => (
  <div {...shared} className={`uikit_alert_container ${type}`}>
    <div className="action_container">
      <div className="action_icon">
        <Icon styled="none" size="large" icon={actionIcon} />
      </div>
    </div>
    <div className="details_container">{children}</div>
    <div className="close_container">
      <div className="close_icon">
        <Icon styled="none" size="large" icon={closeIcon} onClick={onClose} />
      </div>
    </div>
  </div>
);

AlertContainer.defaultProps = defaultProps;

export default AlertContainer;
