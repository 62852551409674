import React, { ReactElement } from 'react';
import './Button.scss';

type ButtonStyle = 'primary' | 'secondary' | 'none';
type ButtonType = 'button' | 'submit';
type ButtonSize = 'normal' | 'large' | 'xlarge' | 'xxlarge';

export interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'> {
  /** Sets the css styling of the button component. 'primary' | 'secondary' | 'none' */
  styled: ButtonStyle;
  /** Sets type of button for functionality. 'button' | 'submit' */
  type: ButtonType;
  /** Sets button size. 'normal' | 'large' */
  size: ButtonSize;
  /** Allows custom styling */
  className: string;
}

const defaultProps = {
  styled: 'primary',
  size: 'normal',
  type: 'button',
  className: '',
};
export const Button = ({ styled, size, type, children, className, ...shared }: ButtonProps): ReactElement => (
  <button
    type={type}
    className={`uikit_button ${styled} ${size} ${shared.disabled && 'disabled'} ${className}`}
    {...shared}
  >
    {children}
  </button>
);

Button.defaultProps = defaultProps;

export default Button;
