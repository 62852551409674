import React from 'react';
import Icon from '../icon/Icon';
import { LabelProps } from './interface';
import './Multiselect.scss';

interface SideInputProps {
  selectedValue: string[] | undefined;
  removeSlection: (event: React.MouseEvent<HTMLElement>, found: LabelProps) => void;
  allLables: LabelProps[] | [];
}

const MultiselectSideInput = ({ selectedValue, removeSlection, allLables }: SideInputProps) => {
  return (
    <section className="uikit_multiselect_input">
      {selectedValue?.map((val, index) => {
        const found = allLables.find(lable => lable.value === val);
        if (found) {
          return (
            <section className="uikit_multiselect_input_card" key={index}>
              <Icon styled="none" size="normal" icon="fal fa-times-circle" onClick={e => removeSlection(e, found)} />
              {found.label}{' '}
            </section>
          );
        }
        return null;
      })}
    </section>
  );
};

export default MultiselectSideInput;
