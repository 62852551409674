import React, { useCallback } from 'react';
import './Radio.scss';
import RadioGroup from './RadioGroup';
import { RadioButtonProps } from './interface';

interface CompoundedComponent
  extends React.ForwardRefExoticComponent<RadioButtonProps & React.RefAttributes<HTMLInputElement>> {
  Group: any;
  defaultProps: any;
}

const RadioButton = React.forwardRef(
  (
    { selectRadio, value, groupSelected, children, disabled, className, name, ...shared }: RadioButtonProps,
    ref: React.Ref<HTMLInputElement>
  ): React.ReactElement => {
    const selectMyself = useCallback(() => {
      if (!disabled && selectRadio) {
        selectRadio(value);
      }
    }, [disabled, selectRadio, value]);

    return (
      <button
        type="button"
        className={`uikit_radiobutton ${disabled && 'disabled'} ${className}`}
        onClick={selectMyself}
        name={name}
      >
        <input
          ref={ref}
          name={name}
          value={value}
          type="radio"
          disabled
          {...shared}
          checked={value === groupSelected}
        />
        <label htmlFor={`radio-${value}`}>{children}</label>
      </button>
    );
  }
) as CompoundedComponent;

RadioButton.defaultProps = {
  disabled: false,
  selectRadio: (val: string | number): string | number => {
    return val;
  },
  className: '',
};

RadioButton.Group = RadioGroup;

export default RadioButton;
