import React from 'react';
import './Multiselect.scss';
import { CheckboxProps } from '../checkbox/interface';
import Checkbox from '../checkbox/index';

const MultiOption = ({
  value,
  children,
  ...shared
}: Omit<CheckboxProps, 'groupSelected' | 'boxName' | 'selectCheckbox' | 'className'>): React.ReactElement => {
  return (
    <Checkbox value={value} {...shared}>
      {children}
    </Checkbox>
  );
};

export default MultiOption;
