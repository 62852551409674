/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ReactElement, ReactNode, useState, useEffect } from 'react';
import './Panel.scss';

export enum CaretStyles {
  Normal,
  Tree,
  None,
}

export enum PanelStyles {
  Default,
  Tree,
  Simple,
  NoCollapse,
}

interface PanelProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'title'> {
  title: ReactNode;
  children: ReactNode;
  footer?: ReactNode; // only available for non collapse atm
  subTitle?: ReactNode;
  onToggle?: () => void; // to let parent know search was completed
  caretStyle?: CaretStyles;
  panelStyle?: PanelStyles;
  expanded?: boolean;
  collapse?: boolean;
  useDropDown?: boolean;
}

export const Panel = ({
  title,
  subTitle,
  children,
  footer,
  caretStyle = CaretStyles.None,
  panelStyle = PanelStyles.NoCollapse,
  expanded = false,
  useDropDown = false,
  onToggle,
  ...props
}: PanelProps): ReactElement => {
  const [opened, setOpened] = useState(expanded);
  const { collapse } = props;

  let component = null;
  let body = null;

  useEffect(() => {
    if (collapse !== null) {
      setOpened(false);
    }
  }, [collapse]);

  useEffect(() => {
    setOpened(expanded);
  }, [expanded]);

  const togglePanel = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    // event.nativeEvent.stopImmediatePropagation();
    event.nativeEvent.stopPropagation();
    setOpened(!opened);
    if (onToggle) {
      onToggle();
    }
  };

  switch (caretStyle) {
    case CaretStyles.Normal:
      component = <i className={`${opened ? 'fal fa-chevron-up mr-4' : 'fal fa-chevron-down mr-4'}'`} />;
      break;
    // case CaretStyles.Tree:
    //   component = (
    //     <i className={`${opened ? 'fal fa-chevron-up mr-4' : 'fal fa-chevron-down mr-4'}'`} onClick={togglePanel} />
    //   );
    //   break;
    case CaretStyles.None:
      component = <></>;
      break;
    default:
      component = <></>;
  }

  switch (panelStyle) {
    case PanelStyles.Tree:
      body = (
        <div className="simple-panel">
          <div className={`simple-panel-heading ${opened ? 'expanded' : ''}`}>
            <div className="simple-panel-heading_content treeview" onClick={togglePanel}>
              {component}
            </div>
            {title}
          </div>

          <div className={`${opened ? 'simple-panel-body visible' : 'hidden'}`}>{children}</div>
        </div>
      );
      break;
    case PanelStyles.Simple:
      body = (
        <div className="simple-panel">
          <div className={`simple-panel-heading ${opened ? 'expanded' : ''}`} onClick={togglePanel}>
            <div className="simple-panel-heading_content">{component}</div>
            {title}
          </div>

          <div className={`${opened ? 'simple-panel-body visible' : 'hidden'}`}>{children}</div>
        </div>
      );
      break;
    case PanelStyles.NoCollapse:
      body = (
        <div className="panel">
          <div className="panel-heading panel-no-collapse-heading expanded">
            <div className="panel-no-collapse-heading_content">{component}</div>
            <div className="title">{title}</div>
            {subTitle && <div className="sub-title">{subTitle}</div>}
          </div>

          <div className={`panel-body visible ${footer ? 'has-footer' : ''}`}>{children}</div>
          {footer && <div className="panel-footer">{footer}</div>}
        </div>
      );
      break;
    default:
      body = (
        <div className="panel">
          <div className={`panel-heading ${opened ? 'expanded' : ''}`} onClick={togglePanel}>
            <div className="panel-heading_content">{component}</div>
            {/* <div className="title">{title}</div>
            {subTitle && <div className="sub-title">{subTitle}</div>} */}
            <span className="title">
              {title} {subTitle && <span className="sub-title">{subTitle}</span>}
            </span>
          </div>
          <div className={`${opened ? 'panel-body visible' : 'hidden'}`}>{children}</div>
        </div>
      );
  }

  return body;
};

export default Panel;
